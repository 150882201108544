<template>
    <router-view></router-view>
</template>

<script>

export default {
    name: 'App',
    components: {
    },
    mounted() {
        $('.datepicker').datepicker({
            templates: {
                leftArrow: '<i class="fa-solid fa-chevron-left"></i>',
                rightArrow: '<i class="fa-solid fa-chevron-right"></i>'
            },
            orientation: "auto bottom"
            // language : 'ja'
        });
    },
}
</script>