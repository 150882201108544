import moment from 'moment'

const filters = {
    formatDate (date, format = "YYYY年MM月DD日") {
        if (!date) {
            return ''
        }
        return moment(date).format(format)
    },
}

export default filters