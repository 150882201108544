import moment from 'moment'

export default {

    formData(data) {
        var formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (value == null) {
            } else if (Array.isArray(value)) {
                value.forEach((v, i) => {
                    formData.append(key + `[]`, JSON.stringify(v))  // arrayデータを分割して入れ直す
                })
            } else {
                formData.append(key, value)
            }
        })
        return formData
    },

    filterNull(data) {
        let dic = {}
        Object.keys(data).forEach(key => {
            const val = data[key]
            if (!!val) dic[key] = val;
        });
        return dic
    },
}