import Vuex from 'vuex'

export default new Vuex.Store({
    state: {
        token: localStorage.getItem('token') || '',
        cargoEditList: [],
        shipEditList: [],
        cargoFilter: {},
        shipFilter: {},
        ships: [],
        areas: [],
        ports: [],
        routes: [],
        user: {}
    },
    mutations: {
        setToken(state, token) {
            if (token) {
                state.token = token
                localStorage.setItem('token', token)
            }
        },
        removeToken(state) {
            localStorage.removeItem('token')
        },
        removeData(state) {
            state.cargoEditList = []
            state.shipEditList = []
            state.cargoFilter = []
            state.shipFilter = []
            state.user = {}
            state.ships = []
            state.areas = []
            state.ports = []
            state.routes = []
        },
        setCargoEditList(state, list) {
            state.cargoEditList = list
        },
        setShipEditList(state, list) {
            state.shipEditList = list
        },
        setShips(state, list) {
            state.ships = list
        },
        setAreas(state, list) {
            state.areas = list
        },
        setPorts(state, list) {
            state.ports = list
        },
        setRoutes(state, list) {
            state.routes = list
        },
        setUser(state, data) {
            state.user = data
        }
    },
    getters: {
        isLoggedIn(state) {
          return !!state.token;
        },
        ships(state) {
            return state.ships.map(ship => {
                return {
                    ...ship,
                    value: ship.id,
                    label: ship.name
                }
            })
        },
        ship_type_options(state) {
            const options = [];
            state.ships.forEach(ship => {
                if (options.find(option => ship.type === option.value)) return;
                options.push({
                    value: ship.type,
                    label: ship.type
                })
            });
            return options;
        },
        areas(state) {
            return state.areas.map(area => {
                return {
                    ...area,
                    value: area.id,
                    label: area.name
                }
            })
        },
        ports(state) {
            return state.ports.map(port => {
                return {
                    ...port,
                    value: port.id,
                    label: port.name
                }
            })
        },
        routes(state) {
            return state.routes.map(route => {
                return {
                    ...route,
                    value: route.id,
                    label: route.name
                }
            })
        },
        user(state) {
            return state.user
        }
    },
})
