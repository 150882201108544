function layout (path) {
    return () => import(`../layouts/${path}`)
}
function page (path) {
    return () => import(`../pages/${path}`)
}
  
 export const routes = [
    {
        path: '/auth',
        name: 'auth',
        component: layout( 'auth.vue' ),
        children: [
            {
                path: '/login',
                name: 'login',
                component: page('auth/login.vue')
            },
        ]
    },
    {
        path: '/',
        name: 'main',
        component: layout( 'main.vue' ),
        meta: { requiresAuth: true },
        children: [
            {
                path: '/',
                name: 'home',
                redirect: { name: 'login' }
            },
            {
                path: '/list/cargo',
                name: 'cargo.index',
                component: page('cargo/index.vue')
            },
            {
                path: '/edit/cargo',
                name: 'cargo.edit',
                component: page('cargo/edit.vue')
            },
            {
                path: '/list/ship',
                name: 'ship.index',
                component: page('ship/index.vue')
            },
            {
                path: '/edit/ship',
                name: 'ship.edit',
                component: page('ship/edit.vue')
            },
            {
                path: '/card',
                name: 'card',
                component: page('card.vue')
            },
        ]
    },
];