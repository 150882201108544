import './bootstrap';
import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/router';
import BootstrapVueNext from 'bootstrap-vue-next'
import '@/assets/scss/app.scss';
import store from '@/store';
import filters from '@/filters';

const app = createApp(App)
app.use(router)
app.use(store)
app.use(BootstrapVueNext)
app.mount('#app')

app.config.globalProperties.$filters = filters