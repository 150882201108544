import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeEach((to, from, next) => {
    if (to.name == from.name || to.path == from.path) return next()
    const isLoggedIn = localStorage.getItem('token')
    if (to.matched.some(m => m.meta.requiresAuth)) {
        if (isLoggedIn) {
            return next()
        } else {
            return next({ name: 'login' })
        }
    } else {
        if (isLoggedIn) {
            return next({ name: 'cargo.index' })
        } else {
            return next()
        }
    }
})

export default router